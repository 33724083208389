.Row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.Column {
  flex: 25%;
  max-width: 50%;
  padding: 4px;
}
