.Contact {
  margin: 1em 23em 1em 23em;
  padding: 1em;
  border-radius: 5px;
  background: rgba(75, 75, 75, 0.3);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
}

.ContactItem {
  padding: 10px;
}

.ContactItem a {
  text-decoration: none;
  color: #ebebed;
}

.ContactText {
  padding: 0.5em;
}

@media (max-width: 499px) {
  .Contact {
    margin: 1em 2em 1em 2em;
  }
}
