.EducationCard {
  margin: auto;
  width: 80%;
}

.EducationForm textarea {
  width: 100%;
  height: 500px;
  color: #bb8c71;
  background-color: #1e1e1e;
}

@media (max-width: 499px) {
  .EducationCard {
    width: 98% !important;
  }
}
