.TitleCard {
  text-align: center;
  position: relative;
  transition: transform 1s;
}
.TitleCard:hover {
  transform: scale(1.02);
}

.TitleCard img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100%;
  position: relative;
  border-radius: 10px;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
}

.CardText {
  position: absolute;
  text-align: center;
  transform: translate(0, -150%);
}

.CardText h2 {
  text-shadow: 5px 5px 10px black;
  color: white;
}

.TitleCardMe {
  cursor: default;
}
