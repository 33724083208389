body {
  margin: 0;
  /* font-family: "Rubik", sans-serif; */
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ebebed;
  background: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: auto !important;
  overflow-x: hidden !important;
  background-color: #313131;
  /* background-image: url("https://chrisslaight.com/images/black-smooth-textured-paper.jpg"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background: #313131;
  background-attachment: scroll;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  /* background: url("https://chrisslaight.com/images/black-smooth-textured-paper.jpg") */
    /* no-repeat center center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll;
}

.Card {
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 1em;
  border-radius: 5px;
  background: rgba(75, 75, 75, 0.3);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
}

.Card strong {
  color: #24cc44;
}

.Card a {
  text-decoration: none;
  font-size: x-large;
  color: white;
}

.Card li {
  margin: 5px 0;
}

@media (max-width: 499px) {
  .Card.ResumeItems {
    padding: 0.3em;
  }
  .Card li {
    font-size: 0.8em;
    margin-left: -2em;
  }

  .Card p {
    font-size: 0.8em;
  }
}