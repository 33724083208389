.Homepage {
  display: grid;
  grid-template-columns: 55% 45%;
}

@media (max-width: 769px) {
  .Homepage {
    display: inline;
  }
}
