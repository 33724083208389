.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: #1e1e1e;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}
