.NavigationItem {
  margin: 0;
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
}

.NavigationItem a {
  color: #ebebed;
  height: 100%;
  padding: 16px 10px;
  border-bottom: 4px solid transparent;
  text-decoration: none;
  font-size: 1.6rem;
}

@media (max-width: 499px) {
  .NavigationItem a {
    font-size: 1.5rem;
  }
}
