.InfoPanelImage img {
  width: 100%;
  height: auto;
  padding: 4px;
  border-radius: 5px;
}

.InfoPanelContent {
  margin-top: -5rem;
}

@media (max-width: 499px) {
  .InfoPanelImage img {
    padding: 0px;
    border-radius: 0px;
  }
  .InfoPanelContent {
    margin-top: -7rem;
  }
}
