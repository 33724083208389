.LoginForm {
  border-radius: 20px;
  background: rgba(75, 75, 75, 0.3);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
  padding: 1em;
  margin-left: 10em;
  margin-right: 10em;
}

.LoginForm button {
  font-size: 2em;
  border-radius: 10px;
  padding: 5px;
  border: none;
  cursor: pointer;
  margin: 5px 0;
}

.FormItems {
  margin: 2em;
}

.FormItems input {
  padding: 10px;
  margin: 5px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 2px solid white;
  color: white;
  font-size: 1em;
}

@media (max-width: 699px) {
  .LoginForm {
    margin-left: 2em;
    margin-right: 2em;
  }

  .LoginForm button {
    font-size: 1em;
  }
}
