.Name {
  text-shadow: 1px 1px 10px black;
}

.Blink {
  animation: blinker 0.8s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.SubHeaderText {
  color: #24cc44;
}

@media (max-width: 499px) {
  .SubHeaderText {
    font-size: 0.8em;
  }

  .ResponsivePageTitle {
    padding-top: 2.5em;
  }
}

@media (min-width: 500px) and (max-width: 650px) {
  .ResponsivePageTitle span {
    padding-top: 2.5em;
  }
}

@media (max-width: 770px) and (min-width: 1150px) {
  .ResponsivePageTitle > span {
    font-size: 0.5em;
  }
}
